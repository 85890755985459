<template>
  <span
    class="ui-icon"
    :style="{
      margin: noMargin?'0px':'2px',
      width: size?`${parseInt(size)+2}px`: '18px',
      height: size?`${parseInt(size)+2}px`: '18px',
      background: `url(${src}) center center / cover no-repeat`,
    }"
  ></span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="less">
.ui-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
