/**
 * 法律智库API模块
 */

import request from '@/utils/request'

export default {
  getExpertInterview: (params) => {
    return request.get('/api/frLegalPlatform/web/thinkTank/expertInterview/page',
      {
        params: params
      }
    )
  },
  getLawBbs: (params) => {
    return request.get('/api/frLegalPlatform/web/thinkTank/lawBbs/page',
      {
        params: params
      }
    )
  },
  getResearch: (params) => {
    return request.get('/api/frLegalPlatform/web/thinkTank/research/page',
      {
        params: params
      }
    )
  },
}
