/**
 * 公共模块API模块
 */

import request from '@/utils/request'

export default {
  getDetail(id) {
    return request.get(`/api/frLegalPlatform/web/consulting/${id}`)
  },

  addConsulting(data) {
    return request.post(`/api/frLegalPlatform/web/consulting/add`, data)
  },
}
