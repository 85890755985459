<template>
  <BiLayoutBox style="margin: 20px 0">
    <template v-slot:left>
      <div class="title">
        <Icon :src="docImage" size="12" />
        <span style="margin-left: 10px">{{ $t("3a505c4") }}</span>
      </div>
      <SideBar :nav-list="sideNav" />
    </template>
    <template v-slot:right>
      <router-view key="key" />
    </template>
  </BiLayoutBox>
</template>

<script>
import Icon from '@/components/Icon'
import BiLayoutBox from '@/components/BiLayoutBox'
import SideBar from '@/components/information/SideBar'
import docImage from '@/assets/information/doc.png'

import { breadcrumbList } from './breadcrumb'

export default {
  name: 'SubLayout',
  components: {
    BiLayoutBox,
    SideBar,
    Icon,
  },
  data() {
    return {
      docImage,
      sideNav: [
        {
          path: '/information/policy',
          name: '0165560',
        },
        { path: '/information/news', name: 'a2374c4' },
        {
          path: '/information/trends',
          name: '01e7805',
        },
        {
          path: '/information/train',
          name: '90e460a',
        },
        {
          path: '/information/notice',
          name: '4f3d806',
        },
      ],
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
    showServiceHeader() {
      return this.$route.path.includes("/service")
    },
    navList() {
      return breadcrumbList(this.$route.path)
    }
  },
}
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.layout {
  background: #f7f8fa;
}
.title {
  box-sizing: border-box;
  background: #f9f9f9;
  color: rgba(153, 153, 153, 1);
  height: 36px;

  padding: 8px 10px;
  font-size: 12px;

  display: flex;
  align-items: center;
}
</style>
