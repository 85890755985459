
import request from '@/utils/request'

export default {
  getLegalResearchResultTypes: () => {
    return request.get(`/api/frLegalPlatform/web/legalResearchResult/list`)
  },
  getLegalResearchResultPage: (params) => {
    return request.get(`/api/frLegalPlatform/web/legalResearchResult/page`, { params })
  },
  getLegalResearchResultDetails: (id) => {
    return request.get(`/api/frLegalPlatform/web/legalResearchResult/${id}`)
  },
}
