/**
 * 内容管理 需求管理 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/consulting/page`, { params: data })
  },
  send: (data) => {
    return request.post('/api/frLegalPlatform/web/contact/add', data)
  }
}
