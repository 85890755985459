import dayjs from 'dayjs'

const date = function(val, arg) {
  if (!val) {
    return '--'
  } else {
    return dayjs(val).format(arg)
  }
}
export default date
