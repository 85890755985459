import API from '@/api'
import router from './../router'
import { Notification } from 'element-ui'

export function dealJump(url) {
  url && router.push({ path: url })
  Notification.closeAll()
}
export function haveReportDown(id, callback) {
  Notification.closeAll()
  API.common.setReportStatus(id).then(res => {
    setTimeout(() => {
      callback && callback() // this.getReportResult()
    }, 1000)
  })
}
export function downloadFile(data, callback) {
  const el = document.createElement("a")
  el.href = data.url
  el.target = "_self"
  el.style.display = "none"
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el) // 下载完成移除元素'
  callback && callback(data)
}

export function dealReportModalClose() {
  const timeoutId = sessionStorage.getItem('timeoutId')
  timeoutId && clearTimeout(timeoutId)
  sessionStorage.setItem('timeoutId', '')
  Notification.closeAll()
}
