import API from '@/api'
import { log } from '@/utils/helper'

const state = {
  lawRegulationsList: {
    pageData: {
      content: [],
      page: {},
    }
  },
  lawRegulationsDetails: {
  },
  analysisData: {
  },
}

const getters = {
  lawRegulationsList(state) {
    return state.lawRegulationsList
  },
  lawRegulationsDetails(state) {
    return state.lawRegulationsDetails
  },
  analysisData(state) {
    return state.analysisData
  },
}

const actions = {
  getLawRegulationsList: ({ commit }, data) => {
    return API.legal.getLawRegulationsList(data)
      .then((res) => {
        commit('getLawRegulationsList', res)
      }).catch(() => {
        log('Interface exception API.legal.getLawRegulationsList()')
      })
  },
  getLawRegulationsDetails: ({ commit }, data) => {
    return API.legal.getLawRegulationsDetails(data)
      .then((res) => {
        commit('getLawRegulationsDetails', res)
      }).catch(() => {
        log('Interface exception API.legal.getLawRegulationsDetails()')
      })
  },
  getJudicialCaseList: ({ commit }, data) => {
    return API.legal.getJudicialCaseList(data)
      .then((res) => {
        commit('getJudicialCaseList', res)
      }).catch(() => {
        log('Interface exception API.legal.getJudicialCaseList()')
      })
  },
  getJudicialCaseDetails: ({ commit }, data) => {
    return API.legal.getJudicialCaseDetails(data)
      .then((res) => {
        commit('getJudicialCaseDetails', res)
      }).catch(() => {
        log('Interface exception API.legal.getJudicialCaseDetails()')
      })
  },
  getAnalysisData: ({ commit }, data) => {
    return API.legal.getAnalysisData(data)
      .then((res) => {
        commit('getAnalysisData', res)
      }).catch(() => {
        log('Interface exception API.legal.getAnalysisData()')
      })
  },
}

const mutations = {
  getLawRegulationsList: (state, data) => {
    let result = {
      pageData: {
        content: [],
        page: {},
      }
    }
    if (data && data.pageData) {
      result = data
    }
    state.lawRegulationsList = result
  },
  getLawRegulationsDetails: (state, data) => {
    state.lawRegulationsDetails = data || {}
  },
  getJudicialCaseDetails: (state, data) => {
    state.lawRegulationsDetails = data || {}
  },
  getJudicialCaseList: (state, data) => {
    let result = {
      pageData: {
        content: [],
        page: {},
      }
    }
    if (data && data.pageData) {
      result = data
    }
    state.lawRegulationsList = result
  },
  getAnalysisData: (state, data) => {
    state.analysisData = data || {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
