import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// element-ui
import locale from 'element-ui/lib/locale'

import zh from './lang/zh'
import en from './lang/en'
import ru from './lang/ru'

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh',
  messages: {
    zh,
    en,
    ru,
  },
  // 去除警告信息
  silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value))

export default i18n
