export function getCookie(name) {
  const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
  const arr = document.cookie.match(reg)
  if (arr) {
    return unescape(arr[2])
  } else {
    return null
  }
}

export function delCookie(name) {
  const Days = 30
  const exp = new Date()
  exp.setTime(exp.getTime() - Days * 24 * 60 * 60 * 30)

  // 这里一定要注意，如果直接访问ip的话，不用注明域名domain
  // 但访问的是域名例如www.baidu.com时，翻译插件的cookie同时存在于一级和二级域名中
  // 即删除翻译cookie时要把domain=www.baidu.com和domain=.baidu.com两个cookie一起删除才行
  var domain = document.domain
  var domainIsIp = false
  var dd = domain.split(".")
  if (dd.length === 4) {
    domainIsIp = true
  }
  document.cookie = name + "='';path=/;expires=" + exp.toUTCString()
  if (domainIsIp === false) {
    domain = "." + dd[1] + "." + dd[2]
    document.cookie = name + "='';domain=" + domain + ";expires=" + exp.toGMTString() + ";path=/"
  }
}
