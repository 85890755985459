import Cookies from 'js-cookie'

const TokenKey = 'accessToken'
const UserInfoKey = 'userInfo'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  // let result = localStorage.getItem(UserInfoKey)
  let result = Cookies.get(UserInfoKey)
  if (result && getToken()) {
    result = JSON.parse(result)
  } else {
    result = {}
  }
  return result
}

export function setUserInfo(res) {
  // localStorage.setItem(UserInfoKey, JSON.stringify(res))
  Cookies.set(UserInfoKey, JSON.stringify(res))
}

export function removeUserInfo() {
  // localStorage.removeItem(UserInfoKey)
  Cookies.remove(UserInfoKey)
}

export const isOrganization = () => {
  const userType = getUserInfo().userType
  const arr = ['LAW_OFFICE', 'TRANSLATE', 'OTHER']
  return arr.includes(userType)
}

// 机构需求管理，需求合作，服务管理，服务合作都有
// 企业和个人没有需求合作和服务管理。企业和个人不能报名需求。
// 法律：只有律师律所机构可以报名法律需求。
// 翻译： 只有翻译机构可以报名翻译需求。
const permissions = {
  'TRANSLATE': {
    '/usercenter/organization': true,
    '/content/request/cooperation': true,
  },
  'LAW_OFFICE': {
    '/usercenter/organization': true,
    '/content/request/cooperation': true,
  },
  'OTHER': {
    '/content/request/cooperation': true,
  },
  'LAWYER': {
    '/content/request/cooperation': true,
    '/usercenter/organization': false,
  },
  'ENTERPRISE': {
    '/content/request/cooperation': false,
    '/content/service/manage': false,
    '/usercenter/organization': false,
  },
  'PERSON': {
    '/content/request/cooperation': false,
    '/content/service/cooperation': true,
    '/content/service/manage': false,
    '/usercenter/organization': false,
  },
}

export function hasPermission(path) {
  const userType = getUserInfo().userType
  let result = permissions[userType][path]
  if (typeof result === 'undefined') {
    result = true
  }
  return result
}
