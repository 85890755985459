<template>
  <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      v-for="(item, index) of data"
      :key="index"
      :to="item.path ? { path: item.path } : undefined"
      :class="{lastItem: index + 1 === data.length}"
      :style="styleObj(index + 1, data.length)"
    >
      {{ is$t ? $t(item.name) : item.name }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    data: {
      type: Array,
      required: true,
    },
    is$t: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    styleObj(index, len) {
      if (index === len) {
        const w = 100 / len
        return {
          width: `${w}%`
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.breadcrumb {
  height: 54px;
  line-height: 54px;
  .lastItem {
    /*display: inline-block;*/
    /*width: 100%;*/
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
</style>
