<template>
  <div class="user-layout">
    <slot name="bread">
      <div style="height: 20px"></div>
    </slot>
    <div class="user-layout-body">
      <div class="user-layout-left">
        <el-menu
          class="user-layout-nav"
          :default-active="this.$route.path"
          @select="select"
        >
          <template v-for="item in nav">
            <!-- 无子目录 -->
            <el-menu-item
              v-if="!item.children || !item.children.length"
              :key="item.name"
              :index="item.path"
            >
              <i class="icon" :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>

            <!-- 有子目录 -->
            <el-submenu v-else :key="item.name" :index="item.name">
              <template slot="title">
                <i class="icon" :class="item.icon"></i>
                <span>{{ item.name }}</span>
              </template>
              <template v-for="lv2 in item.children">
                <el-menu-item
                  v-if="!lv2.hide"
                  :key="lv2.name"
                  :index="lv2.path"
                >
                  {{ lv2.name }}
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <div class="user-layout-right">
        <router-view key="key" />
      </div>
    </div>
  </div>
</template>

<script>
import { hasPermission } from "@/utils/auth";
export default {
  computed: {
    nav() {
      return [
        {
          name: this.$t("ae5c5e3"),
          icon: "icon-account",
          children: [
            { name: this.$t("f9ef53c"), path: "/usercenter/profile" },
            {
              name: this.$t("325a6cb"),
              path: "/usercenter/organization",
              hide: !hasPermission("/usercenter/organization"),
            },
            { name: this.$t("b3e4824"), path: "/usercenter/safety" },
          ],
        },
        // {
        //   name: '系统消息',
        //   icon: 'icon-msg',
        //   path: '/1123123',
        // },
        // {
        //   name: '账号管理',
        //   icon: 'icon-account',
        //   children: [
        //     { name: '账号信息', path: '/123' },
        //     { name: '机构信息', path: '/usercenter/message2' },
        //     { name: '账号安全', path: '/12123' },
        //   ],
        // },
        {
          name: this.$t("bc33a08"),
          icon: "icon-msg",
          path: "/usercenter/system",
        },
        {
          name: this.$t("388e2df"),
          icon: "icon-msg2",
          path: "/usercenter/message",
        },
        {
          name: this.$t("d302280"),
          icon: "icon-bbs",
          path: "/usercenter/myBBS",
        },
        {
          name: this.$t("bc33a09"),
          icon: "icon-bbs",
          path: "/usercenter/download",
        },
      ];
    },
  },
  methods: {
    select(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="less">
.user-layout {
  width: 1200px;
  min-height: calc(100vh - 264px);
  margin: 0 auto 20px;

  &-body {
    display: flex;
  }

  &-left {
    margin-right: 20px;
    width: 260px;
    background: #ffffff;
    box-shadow: 0px -1px 12px 0px rgba(231, 237, 239, 0.5);
    border-radius: 4px;
  }

  &-right {
    min-width: 0;
    flex: 1;
  }

  &-nav {
    border: none;

    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 8px;
      background: no-repeat center;
      background-size: contain;

      &-account {
        background-image: url(~@/assets/usercenter/account-nor.png);
      }

      &-msg {
        background-image: url(~@/assets/usercenter/msg-nor.png);
      }

      &-msg2 {
        background-image: url(~@/assets/usercenter/msg2-nor.png);
      }
      &-bbs {
        background-image: url(~@/assets/usercenter/bbs-nor.png);
      }
    }

    /deep/ .is-active {
      .icon-account {
        background-image: url(~@/assets/usercenter/account-nor.png);
      }

      .icon-msg {
        background-image: url(~@/assets/usercenter/msg-sel.png);
      }

      .icon-msg2 {
        background-image: url(~@/assets/usercenter/msg2-sel.png);
      }
      .icon-bbs {
        background-image: url(~@/assets/usercenter/bbs-sel.png);
      }
    }
  }
}
</style>
