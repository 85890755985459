import coded from './json/ru.json';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
const ru = {
  ...coded,
  ...ruLocale,
  common: {
    pageList: [
      'Главная',
      'услуг',
      'запрос',
      'Продукция',
      'Результаты',
      [ 'Районы', 'Обмен и сотрудничество', 'Оперативные консультации' ],
      // 'форум',
      // 'новост',
      // 'Q&A',
      // 'Умное право',
      'о нас',
    ],
    sign: 'залогин',
    register: 'Регистрация',
    platform: 'Презентация платформы',
    guide: 'Руководство по вступлению',
    contact: 'Связаться с нами',
    address: 'Адрес: Чанцзян роуд 2, демонстрационный район шаньдун, циндао, провинция шаньдун',
    telephone: 'Тел: 0532-55591980',
    fax: 'Факс: 0532-55591980',
    email: 'Почтовый ящик: shfazhigu@163.com',
    organization: 'Комитет по управлению местной демонстрационной зоной экономического и торгового сотрудничества Китайско-Шанхайской организации сотрудничества',
  },
  home: {
    header: {
      searchTypeData: [
        'Законы и правила',
        'Судебные дела',
      ],
      selPla: 'Россия',
      inputPla: [ 'Введите ключевые слова законодательства и нормативных актов', '请输入司法案例关键词' ]
    },
    navList: [ 'адвокат', 'Для арбитражу', 'посредничеств', 'Нотариально', 'идентификац', 'Pro bono' ],
    navListDes1: [ 'Поищи в юридической', 'Понимание процесса', 'Узнайте у диспетчерской', 'Ты знаешь, как это', 'Узнайте о судебной', 'Обратитесь в центр' ],
    navListDes2: [ 'фирме и в справочнике', 'арбитража и структуры', 'комиссии о посредничестве', 'делается', 'экспертизе', 'правовой помощи' ],
    legalNeedsInfo: {
      title: 'Юридический спрос',
      des: 'Спрос на поставку намного проще',
    },
    legalAidInfo: {
      title: 'Юридический сервис',
      des: 'Гораздо проще найти сервис',
    },
    des1List: [ '', '', '', '', '' ],
    des2List: [ 'компанией вступило', 'сделок', 'Уровень успешной стыковки', 'правовых положений', 'Судебные дела' ],
  },
  service: {
    selectionList: [ 'Сфера обслуживания', 'За границей.' ],
    bodyNav: [ 'Умное право' ]
  },
  legalQuery: {
    leftNavBar: [ 'Отдел распространения', 'Категория правил', 'Год выпуска' ]
  }
  // service: {
  //   header: {
  //     list: ['Сфера государственных дел, связанных с иностранными государствами', 'Юридический спрос', 'Юридический сервис']
  //   },
  //   navList: ['Обращение за адвокатом', 'Обращение в арбитраж', 'Обращение к посредничеству', 'Обращение за нотариальным свидетельством', 'Обращение за идентификацией', 'Обратиться за юридической помощью'],
  //   searchTypeNameList: ['Адвокатура', 'Адвокат'],
  //   searchPla: ['Введите ключевые слова адвокатура', 'Введите ключевые слова адвоката', 'Введите ключевые слова арбитража', 'Введите ключевые слова агентства посредничества', 'Введите ключевые слова нотариальной конторы', 'Введите ключевые слова органа экспертизы', 'Введите ключевые слова Центра помощи'],
  //   searchName: 'Поиск',
  //   selectionList: ['Выбранные условия', 'страны', 'провинция', 'Городской район', 'Сортировка', 'Вовлеченная область', 'Тип спроса', 'Статус', 'Вид сервиса'],
  //   releaseDemand: 'Публикация спроса',
  //   demandPla: '请输入法律服务关键词',
  //   releaseDesk: 'На сервисе',
  //   deskPla: '请输入法律需求关键词',
  // },
  // account: {
  //   register: {
  //     commonForm: {
  //       lable: {
  //         userName: '用户名：',
  //         phone: '手机号：',
  //         graphical: '图形验证码：',
  //         short: '手机验证码：',
  //         email: '电子邮箱：',
  //         password: '登录密码：',
  //         confirmPassword: '确认密码：',
  //       },
  //       pla: {
  //         userName: '请输入用户名',
  //         phone: '请输入手机号',
  //         graphical: '请输入验证码',
  //         short: '请输入手机验证码',
  //         email: '请输入电子邮箱',
  //         password: '请设置8-20个字符，区分大小写',
  //         confirmPassword: '请再次输入密码',
  //       }
  //     }
  //   }
  // },
};
export default ru;
