import "core-js/stable"
import "regenerator-runtime/runtime"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/permission'
import store from './store'
import filters from '@/filter'
import './styles/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'
import i18n from './i18n/'
import 'react-quill/dist/quill.snow.css'
import md5 from 'js-md5'
Vue.use(BaiduMap, {
  ak: 'VYXAx7sdRq50Rx2Xui6eYyPeIgP9vUG4'
})
Vue.use(ElementUI)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 跳转页面后回滚到视窗顶部。
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

Vue.config.productionTip = false
Vue.prototype.$md5 = md5
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
