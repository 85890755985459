/*  filter
 *
 *  @init
 *
 */
import { autoExport } from '@/utils/helper'

const context = require.context('@/filter', true, /\w*\.js$/)

const filter = autoExport(context, 'filter')

export default filter
