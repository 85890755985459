/**
 * 服务大厅 - 合同模板 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/contract/template/page`, { params: data })
  },
  getDetail: (id, data) => {
    return request.get(`/api/frLegalPlatform/web/contract/template/${id}`, { params: data })
  },
  getContractOptions: () => {
    return request.get(`/api/frLegalPlatform/web/contract/template/tree/list`)
  },
  getHomeData: () => {
    return request.get(`/api/frLegalPlatform/web/home/exchange/cooperation/zone`)
  }
}
