/**
* 内容中心 咨询管理 API
*/

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/consulting/user/page`, { params: data })
  },
  getDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/consulting/${id}`)
  },
  add: (data) => {
    return request.post('/api/frLegalPlatform/web/lawSearchApply/add', data)
  }
}
