/*  modules
 *
 *  @init
 *
 */

import { autoExport } from '@/utils/helper'

const context = require.context('@/store/modules', true, /\w*\.js$/)

const modules = autoExport(context, 'store')

export default modules
