import { getUserInfo } from './auth.js'

export const isLogin = () => {
  const userType = getUserInfo().userType
  return !!userType
}

// ENTERPRISE:企业用户, PERSON:个人用户, LAWYER:律师用户, LAW_OFFICE:律所机构, TRANSLATE:翻译机构, OTHER:其他机构
export const isServiceManage = () => {
  const userType = getUserInfo().userType
  // 只有机构用户和律师才有服务管理模块
  // const arr = ['LAWYER', 'LAW_OFFICE', 'TRANSLATE', 'OTHER']
  const arr = ['LAWYER', 'LAW_OFFICE', 'TRANSLATE']
  const result = userType ? arr.includes(userType) : true
  console.log('result', result)
  return result
}

export const isPersonEnterprise = () => {
  const userType = getUserInfo().userType
  const arr = ['PERSON', 'ENTERPRISE']
  return arr.includes(userType)
}
