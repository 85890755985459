import API from '@/api'
import { log } from '@/utils/helper'

const state = {
  thinkData: {
    page: {
    },
    content: []
  },
}

const getters = {
  thinkData(state) {
    return state.thinkData
  },
}

const actions = {
  getExpertInterview: ({ commit }, data) => {
    return API.think.getExpertInterview(data)
      .then((res) => {
        commit('getThinkData', res)
      }).catch(() => {
        log('Interface exception API.think.getExpertInterview()')
      })
  },
  getLawBbs: ({ commit }, data) => {
    return API.think.getLawBbs(data)
      .then((res) => {
        commit('getThinkData', res)
      }).catch(() => {
        log('Interface exception API.think.getLawBbs()')
      })
  },
  getResearch: ({ commit }, data) => {
    return API.think.getResearch(data)
      .then((res) => {
        commit('getThinkData', res)
      }).catch(() => {
        log('Interface exception API.think.getResearch()')
      })
  },
}

const mutations = {
  getThinkData: (state, data) => {
    let result = {
      page: {},
      content: []
    }
    if (data && data.content) {
      result = data
    }
    state.thinkData = result
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
