<template>
  <div class="layout" :class="classObj">
    <Header />
    <FixedJumpCard v-if="showCommonFixed" />
    <ServiceHeader v-if="showServiceHeader" />
    <CommonHeader v-else-if="showCommonHeader" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="router-view" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="router-view" />
    <!-- <router-view key="router1" /> -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer"
import Header from "@/components/Header"
import ServiceHeader from "@/components/Service/ServiceHeader"
import CommonHeader from "@/components/CommonHeader"
import FixedJumpCard from "@/components/FixedJumpCard"
export default {
  name: "Layout",
  components: {
    Header,
    ServiceHeader,
    CommonHeader,
    Footer,
    FixedJumpCard,
  },
  computed: {
    key() {
      return this.$route.path;
    },
    showServiceHeader() {
      return this.$route.path.startsWith("/service");
    },
    showCommonHeader() {
      const array = [
        "/legal",
        "/business",
        "/about",
        "/think",
        "/information",
        "/communication",
      ];
      const path = this.$route.path;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (path.startsWith(element)) {
          return true;
        }
      }
      return false;
    },
    showCommonFixed() {
      const array = ["/account/login", "/account/register", "/consult"];
      const path = this.$route.path;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (path.includes(element)) {
          return false;
        }
      }
      return true;
    },
    classObj() {
      const array = [
        "/service/lawyer",
        "/service/arbitration",
        "/service/mediate",
        "/service/notarization",
        "/service/appraisal",
        "/service/legalAid",
      ];
      const path = this.$route.path;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (path.startsWith(element)) {
          return {
            layoutF: true,
          };
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.layout {
  background: #f7f8fa;
}
.layoutF {
  background: #fff;
}
</style>
