
export default class TreeList {
  // _rootList;

  constructor(list) {
    this._rootList = [...list]
  }

  get list() {
    return this._rootList
  }

  find(cb) {
    const queue = [...this._rootList]
    while (queue.length) {
      const cur = queue.shift()
      if (cb(cur)) {
        return cur
      }
      if (cur.children) {
        for (const i of cur.children) {
          queue.push(i)
        }
      }
    }
  }
}
