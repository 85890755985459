/**
 * 公共模块API模块
 */

import request from '@/utils/request'

export default {
  // getLawRequirements: (params) => {
  //   return request.get('/api/frLegalPlatform/web/lawRequirements/page',
  //     {
  //       params: params
  //     }
  //   )
  // },

  // =============== 教育 ================

  // 教育列表
  getEduList(params) {
    return request.get(`/api/frLegalPlatform/web/educationTraining/page`, {
      params,
    })
  },
  // 教育详情
  getEduDetail(id) {
    return request.get(`/api/frLegalPlatform/web/educationTraining/${id}`)
  },

  // =============== 通知公告 ================
  getNoticeList(params) {
    return request.get(`/api/frLegalPlatform/web/notice/page`, { params })
  },
  getNoticeDetail(id) {
    return request.get(`/api/frLegalPlatform/web/notice/${id}`)
  },
}
