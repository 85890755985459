<template>
  <div class="header" :style="styleObj"></div>
</template>

<script>
export default {
  name: 'CommonHeader',
  data() {
    return {
      legalzh: require('@/assets/legal/bg.png'),
      legalen: require('@/assets/legal/bgen.png'),
      legalru: require('@/assets/legal/bgru.png'),
      businesszh: require('@/assets/business/bg.png'),
      businessen: require('@/assets/business/bgen.png'),
      businessru: require('@/assets/business/bgru.png'),
      aboutzh: require('@/assets/about/bg.png'),
      abouten: require('@/assets/about/bgen.png'),
      aboutru: require('@/assets/about/bgru.png'),
      thinkzh: require('@/assets/think/bg.png'),
      thinken: require('@/assets/think/bgen.png'),
      thinkru: require('@/assets/think/bgru.png'),
      informationzh: require('@/assets/information/bg.png'),
      informationen: require('@/assets/information/bgen.png'),
      informationru: require('@/assets/information/bgru.png'),
      communicationzh: require('@/assets/communication/bg.png'),
      communicationen: require('@/assets/communication/bgen.png'),
      communicationru: require('@/assets/communication/bgru.png')
    }
  },
  computed: {
    styleObj() {
      const path = this.$route.path
      const locale = this.$i18n.locale
      const obj = {
        legal: `url(${this['legal' + locale]})`,
        business: `url(${this['business' + locale]})`,
        about: `url(${this['about' + locale]})`,
        think: `url(${this['think' + locale]})`,
        information: `url(${this['information' + locale]})`,
        communication: `url(${this['communication' + locale]})`
      }
      let type = ''
      if (path.includes('/legal')) {
        type = 'legal'
      } else if (path.includes('/business')) {
        type = 'business'
      } else if (path.includes('/about')) {
        type = 'about'
      } else if (path.includes('/think')) {
        type = 'think'
      } else if (path.includes('/information')) {
        type = 'information'
      } else if (path.includes('/communication')) {
        type = 'communication'
      }
      return {
        backgroundImage: obj[type],
      }
    },
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.header {
  position: relative;
  min-width: @uni-width;
  height: 200px;
  background: center no-repeat;
  background-size: 100% 290px;
}
</style>
