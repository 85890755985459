<template>
  <el-menu
    :default-active="this.$route.path"
    text-color="#333333"
    class="side-bar"
    :collapse-transition="false"
    @select="goTo"
  >
    <template v-for="(item, index) in navConfig">
      <el-submenu
        v-if="!item.hide && item.children"
        :key="index"
        :index="`t${index}`"
      >
        <template slot="title">
          <Icon :src="item.icon" />
          <span style="margin-left: 6px">{{ item.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(child, sindex) in item.children"
            v-if="!child.hide"
            :key="`c${sindex}`"
            :index="child.path"
          >
            {{ child.name }}
            <!-- {{ child.name + '-' + child.show }} -->
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item
        v-else-if="!item.hide"
        :key="index"
        class="top-menu-item"
        :index="item.path"
      >
        <Icon v-if="active === item.path" :src="item.activeIcon" />
        <Icon v-else :src="item.icon" />
        {{ item.name }}
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import Icon from "../Icon";

export default {
  name: "SideBar",
  components: {
    Icon,
  },
  props: {
    navConfig: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: "",
      activeLang: localStorage.getItem("lang") || "zh",
    };
  },
  computed: {
    active() {
      return this.$route.path;
    },
  },
  watch: {
    $route(to, from) {
      // this.handleActiveIndex()
    },
  },
  created() {
    // this.handleActiveIndex();
    console.log("create side bar");
  },
  methods: {
    handleContext(index) {
      const str = "common.pageList";
      return this.$t(str)[index];
    },
    goTo(path) {
      this.activeIndex = path;
      this.$router.push({ path });
      this.$emit("select", path);
    },
    goLogin() {
      this.$router.push({
        path: "/account/login",
      });
    },
    goRegister() {
      this.$router.push({
        path: "/account/register",
      });
    },
    changeLang(lang, index) {
      this.activeLang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
    },
    handleActiveIndex() {
      for (let index = 0; index < this.routerData.length; index++) {
        const element = this.routerData[index];
        if (this.$route.path.includes(element)) {
          this.activeIndex = index;
          break;
        } else {
          this.activeIndex = "";
        }
      }
    },
    changeActiveIndex(path, index) {
      this.activeIndex = index;
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>
<style scoped lang="less">
.side-bar {
  /deep/ .el-menu-item {
    color: #999999 !important;
    padding-left: 46px !important;
  }
  .top-menu-item {
    font-weight: 500;
    color: rgb(51, 51, 51) !important;
    padding-left: 20px !important;
  }
  /deep/ .el-menu-item.is-active {
    color: #00a4ff !important;
    background: rgba(0, 164, 255, 0.07);
  }
  /deep/ .el-submenu__title {
    color: #333333;
    font-weight: 500;
  }
}
</style>
