/**
 * 用户中心API模块
 */

import request from '@/utils/request';

export default {
  getMsgList(params) {
    return request.get(`/api/frLegalPlatform/web/contact/page`, { params });
  },
  replyMsg(id, params) {
    return request.post(`/api/frLegalPlatform/web/contact/reply/${id}`, params);
  },
  setRead(id) {
    return request.put(`/api/frLegalPlatform/web/contact/read/${id}`);
  },
  getSystemMsgList(params) {
    return request.get(`/api/frLegalPlatform/web/message/page`, { params });
  },
  getSystemMsgDetails(id) {
    return request.get(`/api/frLegalPlatform/web/message/${id}`);
  },
  changeSystemMsgReaded(ids) {
    return request.put(`/api/frLegalPlatform/web/message/${ids}`);
  },
  getUnread() {
    return request.get('/api/frLegalPlatform/web/message/unread/count');
  },
  getMyBBS() {
    return request.get('/api/frLegalPlatform/web/bbs/my/page');
  },
  delComments(params) {
    return request.delete('/api/frLegalPlatform/web/bbs/comments', { data: params });
  },
  getMyDownload(params) {
    return request.get('/api/frLegalPlatform/web/companyReportRecord/page', { params });
  }
};
