<template>
  <div class="header" :class="classObj">
    <div class="container">
      <div class="content">
        <div
          v-for="(item, index) of serviceList"
          :key="index"
          :class="['uiText', { active: index === activeIndex }]"
          @click="changrActiveIndex(index)"
        >
          {{ $t(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceHeader",
  data() {
    return {
      activeIndex: 0,
      // serviceList: 'service.header.list',
      serviceList: [
        "1a5e6a3",
        "80f2c9f",
        "f01427c",
        "6532fd7",
        "service.bodyNav[0]",
      ],
      routerData: [
        "/service/lawyer",
        "/service/demand",
        "/service/desk",
        "/service/contract",
        "/service/aiLegal",
      ],
    };
  },
  computed: {
    classObj() {
      const locale = this.$i18n.locale;
      return {
        en: locale === "en",
        ru: locale === "ru",
      };
    },
  },
  created() {
    this.handleActiveIndex();
  },
  methods: {
    changrActiveIndex(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.$router.push({
          path: this.routerData[index],
        });
      }
    },
    handleActiveIndex() {
      for (let index = 0; index < this.routerData.length; index++) {
        const path = this.routerData[index];
        if (this.$route.path.includes(path)) {
          this.activeIndex = index;
          break;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.header {
  position: relative;
  min-width: @uni-width;
  height: 380px;
  background: url("~@/assets/service/banner.png") center no-repeat;
  background-size: 100% 100%;
  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.76);
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: @uni-width;
      height: 88px;
      margin: 0 auto;
      .interval {
        width: 2px;
        height: 44px;
        margin: 0 70px;
        background: #fff;
      }
      .uiText {
        position: relative;
        width: 260px;
        height: 48px;
        // margin: 0 70px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 2px;
          height: 44px;
          background: #ffffff;
        }
      }
      .active {
        background: url("~@/assets/service/tapBg.png") center no-repeat;
        background-size: 260px 48px;
      }

      div:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
.en {
  background: url("~@/assets/service/bannerEn.png") center no-repeat;
  background-size: 100% 100%;
}
.ru {
  background: url("~@/assets/service/bannerRu.png") center no-repeat;
  background-size: 100% 100%;
}
</style>
