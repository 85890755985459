<template>
  <div class="footer">
    <div class="container">
      <div class="introduce">
        <router-link class="uiText" to="/about/intro">{{
          $t('common.platform')
        }}</router-link>
        <div class="interval"></div>
        <router-link class="uiText" to="/about/entry">{{
          $t('common.guide')
        }}</router-link>
        <div class="interval"></div>
        <router-link class="uiText" to="/about/contact">{{
          $t('common.contact')
        }}</router-link>
        <!-- <div class="interval"></div>
        <router-link class="uiText" to="/">{{
          $t('common.complaint')
        }}</router-link> -->
      </div>
      <div class="dividingLine"></div>
      <div class="info">
        <div class="uiText">{{ $t('common.address') }}</div>
        <div class="uiText">{{ $t('common.telephone') }}</div>
        <div class="uiText">{{ $t('common.fax') }}</div>
        <div>{{ $t('common.email') }}</div>
      </div>
      <div class="name">{{ $t('common.organization') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.footer {
  width: 100%;
  // height: 180px;
  background: #111b40;
  color: @uni-text-color;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: @uni-width;
    margin: 0 auto;

    .introduce {
      display: flex;
      align-items: center;
      height: 64px;

      .interval {
        width: 1px;
        height: 10px;
        margin: 0 30px;
        background: #ffffff;
        border-radius: 1px;
        opacity: 0.7;
      }

      .uiText {
        color: @uni-text-color;
      }
    }

    .dividingLine {
      width: 100%;
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .uiText {
        margin-right: 25px;
        white-space: nowrap;
      }
    }

    .name {
      height: 62px;
      line-height: 62px;
    }
  }
}
</style>
