/**
 * 资讯 动态 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/serviceDynamics/page`, { params: data })
  },
  getDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/serviceDynamics/${id}`)
  }
}
