/**
 * 公共模块API模块
 */

import request from '@/utils/request'

const caseUrl = '/api/frLegalPlatform/web/case'
const lawUrl = `/api/frLegalPlatform/web/lawService`
const commonUrl = `/api/frLegalPlatform/web`
const serviceOrderUrl = '/api/frLegalPlatform/web/serviceOrder'

export default {
  getStatistics: (params) => {
    return request.get(
      `/api/frLegalPlatform/web/home/statistics`,
      { params }
    )
  },
  getLawRequirementsDetails: (params) => {
    let url = `${commonUrl}/lawRequirements/${params.id}`
    if (params.type === 'reset') {
      url = `${commonUrl}/lawRequirements/user/get/${params.id}`
    }
    return request.get(url)
  },
  addLawRequirements: (data) => {
    return request.post(`${commonUrl}/lawRequirements/add`, data)
  },
  editLawRequirements: (id, data) => {
    return request.put(`${commonUrl}/lawRequirements/${id}`, data)
  },
  getLawServiceDetails: (params) => {
    return request.get(`${commonUrl}/lawService/${params.id}`)
  },
  addLawService: (data) => {
    return request.post(`${commonUrl}/lawService/add`, data
    )
  },
  editLawService: (id, data) => {
    return request.put(`/api/frLegalPlatform/web/lawService/${id}`, data)
  },
  getOrganizationList: (params) => {
    return request.get(
      `/api/frLegalPlatform/web/foreignAffairs/organization/page`,
      { params }
    )
  },
  getLawyerList: (params) => {
    return request.get(
      `/api/frLegalPlatform/web/foreignAffairs/organization/persons`,
      { params }
    )
  },
  getOrganizationDetails: (params) => {
    return request.get(
      `/api/frLegalPlatform/web/foreignAffairs/organization/${params.id}`,
      { params }
    )
  },
  getOrganizationPersonsDetails: (params) => {
    return request.get(
      `/api/frLegalPlatform/web/foreignAffairs/organization/${params.id}/persons`,
      { params }
    )
  },
  addServiceOrderCooperation: (id, data) => {
    return request.post(
      `/api/frLegalPlatform/web/serviceOrder/cooperation/${id}`,
      data
    )
  },

  // =============== 案例
  addCase: (params) => {
    return request.post(`${caseUrl}/add`, params)
  },
  getCase: (id) => {
    return request.get(`${caseUrl}/${id}`)
  },
  editCase: (id, params) => {
    return request.put(`${caseUrl}/${id}`, params)
  },
  deleteCase: (id) => {
    return request.delete(`${caseUrl}/${id}`)
  },

  // ================ 服务
  addLaw: (params) => {
    return request.post(`${lawUrl}/add`, params)
  },
  getLawList: (params) => {
    return request.get(`${lawUrl}/page`, { params })
  },
  getLawUserList: (params) => {
    return request.get(`${lawUrl}/user/page`, { params })
  },
  getLawDetail: (id) => {
    return request.get(`${lawUrl}/${id}`)
  },
  editLaw: (id, params) => {
    return request.put(`${lawUrl}/${id}`, params)
  },
  deleteLaw: (id) => {
    return request.delete(`${lawUrl}/${id}`)
  },
  // addLawService: (data) => {
  //   return request.post(`${commonUrl}/lawService/add`, data)
  // },
  // ===================== 服务订单
  // 取消
  orderCancel: (id) => {
    return request.put(`${serviceOrderUrl}/cancel/cooperation/${id}`)
  },
  // 沟通
  orderCommunicate: (id) => {
    return request.put(`${serviceOrderUrl}/communicated/${id}`)
  },
  // 完成
  orderComplete: (id) => {
    return request.put(`${serviceOrderUrl}/completed/${id}`)
  },
  // 合作
  orderCooperation: (id, params) => {
    return request.post(`${serviceOrderUrl}/cooperation/${id}`, params)
  },
  // 列表
  orderList: (params) => {
    return request.get(`${serviceOrderUrl}/page`, { params })
  },
  // 处理中
  orderProcess: (id) => {
    return request.put(`${serviceOrderUrl}/processing/${id}`)
  },
  // 详情
  orderDetail: (id) => {
    return request.get(`${serviceOrderUrl}/${id}`)
  },
  // 报名
  requestApply: (id) => {
    return request.post(`${commonUrl}/lawRequirements/signUp/${id}`)
  }
}
