<template>
  <BiLayoutBox :nav-list="navList2">
    <template v-slot:left>
      <SideBar :nav-config="sideBarList" />
    </template>
    <template v-slot:right>
      <router-view key="key" />
    </template>
  </BiLayoutBox>
</template>

<script>
import BiLayoutBox from '@/components/BiLayoutBox'
import SideBar from '@/components/SideBar'
import { breadcrumbList } from './breadcrumb'
import { hasPermission } from '@/utils/auth'
import requestImage from '@/assets/content/request.png'
import serviceImage from '@/assets/content/service.png'
import lookupImage from '@/assets/content/lookup.png'
import lookupActiveImage from '@/assets/content/lookup_active.png'
import consultImage from '@/assets/content/consult.png'
import consultActiveImage from '@/assets/content/consult_active.png'
import { isPersonEnterprise } from '@/utils/jurisdiction'
export default {
  name: 'SubLayout',
  components: {
    BiLayoutBox,
    SideBar,
  },
  data() {
    return {
      navList: [
        {
          name: this.$t('f43ad59'),
          path: '/',
        },
        {
          name: '内容管理',
          path: '/service/affairs',
        },
        {
          name: '需求管理',
          path: '',
        },
      ]
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
    showServiceHeader() {
      return this.$route.path.includes('/service')
    },
    sideBarList() {
      return [
        {
          name: this.$t('4c16f1c'),
          icon: requestImage,
          children: [
            { name: this.$t('0956d5f'), path: '/content/request/manage' },
            { name: this.$t('7e467c2'), path: '/content/request/cooperation', hide: !hasPermission('/content/request/cooperation') },
          ],
        },
        {
          name: this.$t('ea71060'),
          icon: serviceImage,
          hide: isPersonEnterprise && !hasPermission('/content/service/cooperation'),
          children: [
            {
              name: this.$t('0b2e1a0'),
              path: '/content/service/manage',
              hide: isPersonEnterprise(),
            },
            { name: this.$t('d8a7e67'), path: '/content/service/cooperation', hide: !hasPermission('/content/service/cooperation') },
          ],
        },
        {
          name: this.$t('83b4d31'),
          icon: lookupImage,
          path: '/content/lookup',
          activeIcon: lookupActiveImage,
        },
        {
          name: this.$t('c0576d8'),
          icon: consultImage,
          activeIcon: consultActiveImage,
          path: '/content/consult',
        },
      ]
    },
    navList2() {
      return breadcrumbList(this.$route.path)
    }
  },
  watch: {
    // $route(to, from) {
    //   this.navList = breadcrumbList(to.path)
    // },
  },
  mounted() {
    this.navList = breadcrumbList(this.$route.path)
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.layout {
  background: #f7f8fa;
}
</style>
