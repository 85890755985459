/**
* 内容中心 查询管理 API
*/

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/lawSearchApply/page`, { params: data })
  },
  getDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/lawSearchApply/${id}`)
  },
  add: (data) => {
    return request.post('/api/frLegalPlatform/web/lawSearchApply/add', data)
  },
  cancel: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawSearchApply/cancel/${id}`)
  }
}
