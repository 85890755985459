import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '@/views/home/Home.vue'
import Layout from "@/layout";
import SubLayout from "@/layout/subLayout";
import InfoLayout from "@/layout/infoLayout";
import UserLayout from "@/layout/userLayout";

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// const allRoutes = require.context('@/views', true, /router.js$/)
// const allRoutesArray = allRoutes.keys().map(key => (allRoutes(key).default || allRoutes(key)))
const routes = [
  // ...allRoutesArray,
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/index")
      }
    ]
  },
  {
    path: "/account",
    component: Layout,
    redirect: "/account/login",
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/account/login/index")
      },
      {
        path: "reset",
        name: "Reset",
        component: () => import("@/views/account/reset/index")
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/account/register/index")
      },
      {
        path: 'register/personal',
        name: 'Personal',
        component: () => import('@/views/account/register/personal/index'),
      },
      {
        path: 'register/enterprise',
        name: 'Enterprise',
        component: () => import('@/views/account/register/enterprise/index'),
      },
      {
        path: 'register/organization',
        name: 'Organization',
        component: () => import('@/views/account/register/organization/index'),
      },
      {
        path: 'register/lawyer',
        name: 'RegisterLawyer',
        component: () => import('@/views/account/register/lawyer/index'),
      },
    ],
  },
  {
    path: "/service",
    component: Layout,
    redirect: "/service/lawyer",
    children: [
      {
        path: "lawyer",
        name: "Lawyer",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "aiLegal",
        name: "aiLegal",
        component: () => import("@/views/service/aiLegal/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "affairs/details",
        name: "affairsDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "demand",
        name: "Demand",
        component: () => import("@/views/service/demand/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "demand/details",
        name: "DemandDetails",
        component: () => import("@/views/service/demand/details/index")
      },
      {
        path: "demand/release",
        name: "DemandRelease",
        component: () => import("@/views/service/demand/release/index")
      },
      {
        path: "desk",
        name: "Desk",
        component: () => import("@/views/service/desk/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "desk/details",
        name: "DeskDetails",
        component: () => import("@/views/service/desk/details/index")
      },
      {
        path: "desk/release",
        name: "DeskRelease",
        component: () => import("@/views/service/desk/release/index")
      },
      {
        path: "legalAid",
        name: "LegalAid",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "legalAid/details",
        name: "legalAidDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "notarization",
        name: "Notarization",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "notarization/details",
        name: "notarizationDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "mediate",
        name: "Mediate",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "mediate/details",
        name: "mediateDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "arbitration",
        name: "Arbitration",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "arbitration/details",
        name: "arbitrationDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "appraisal",
        name: "Appraisal",
        component: () => import("@/views/service/affairs/index"),
        meta: {
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: "appraisal/details",
        name: "appraisalDetails",
        component: () => import("@/views/service/affairs/details/index")
      },
      {
        path: "contract",
        name: "Contract",
        component: () => import("@/views/service/contract/index")
      },
      {
        path: "contract/detail",
        name: "ContractDetail",
        component: () => import("@/views/service/contract/details/index")
      }
    ]
  },
  {
    path: "/legal",
    component: Layout,
    redirect: "/legal/query",
    children: [
      {
        path: "query",
        name: "LegalQuery",
        component: () => import("@/views/legal/index")
      },
      {
        path: "details",
        name: "QueryDetails",
        component: () => import("@/views/legal/details/index")
      },
      {
        path: "apply",
        name: "apply",
        component: () => import("@/views/legal/apply/index")
      },
      {
        path: "analysis",
        name: "analysis",
        component: () => import("@/views/legal/analysis/index")
      },
      {
        path: "info",
        name: "info",
        component: () => import("@/views/legal/info/index")
      },
    ]
  },
  {
    path: "/business",
    component: Layout,
    redirect: "/business/consult",
    children: [
      {
        path: "consult",
        name: "consult",
        component: () => import("@/views/business/consult")
      },
      {
        path: "detail",
        name: "BusinessDetail",
        component: () => import("@/views/business/detail")
      },
      {
        path: "release",
        name: "BusinessRelease",
        component: () => import("@/views/business/release")
      }
    ]
  },
  {
    path: "/content",
    component: Layout,
    redirect: "/content/request/manage",
    children: [
      {
        path: "request",
        name: "Request",
        component: SubLayout,
        children: [
          {
            path: "manage",
            name: "RequestmManage",
            component: () => import("@/views/content/request/manage/index")
          },
          {
            path: "cooperation",
            name: "RequestCooperation",
            component: () => import("@/views/content/request/cooperation/index")
          },
          {
            path: "detail",
            name: "需求详情",
            component: () =>
              import("@/views/content/request/manage/detail/index")
          },
          {
            path: "cooperation_detail",
            name: "需求合作详情",
            component: () =>
              import("@/views/content/request/cooperation/detail/index")
          },
          {
            path: "release",
            name: "RequestRelease",
            component: () => import("@/views/content/request/release/index")
          },
          {
            path: "lookup",
            name: "RequestmLookup",
            component: () => import("@/views/content/request/manage/index")
          }
        ]
      },
      {
        path: "",
        name: "",
        component: SubLayout,
        children: [
          {
            path: "lookup",
            name: "查询管理",
            component: () => import("@/views/content/lookup/index")
          },
          {
            path: "lookup/form",
            name: "新增查询",
            component: () => import("@/views/content/lookup/form")
          },
          {
            path: "lookup/detail",
            name: "查询详情",
            component: () => import("@/views/content/lookup/detail/index")
          },
          {
            path: "consult",
            name: "RequestmConsult",
            component: () => import("@/views/content/consult/index")
          },
          {
            path: "consult/detail",
            name: "RequestmDetail",
            component: () => import("@/views/content/consult/detail/index")
          }
        ]
      },

      {
        path: "service",
        name: "Service",
        redirect: "/manage",
        component: SubLayout,
        children: [
          {
            path: "manage",
            name: "ServiceManage",
            component: () => import("@/views/content/service/manage/index")
          },
          {
            path: "detail",
            name: "ServiceDetail",
            component: () =>
              import("@/views/content/service/manage/detail/index")
          },
          {
            path: "cooperation",
            name: "ServiceCooperation",
            component: () => import("@/views/content/service/cooperation/index")
          },
          {
            path: "cooperationDetail",
            name: "ServiceCooperationDetail",
            component: () =>
              import("@/views/content/service/cooperation/detail/index")
          },
          {
            path: "release",
            name: "ServiceRelease",
            component: () => import("@/views/content/service/release/index")
          }
        ]
      }
    ]
  },
  {
    path: "/about",
    component: Layout,
    redirect: "/about/intro",
    children: [
      {
        path: "intro",
        name: "Intro",
        component: () => import("@/views/about/index")
      },
      {
        path: "entry",
        name: "Entry",
        component: () => import("@/views/about/index")
      },
      {
        path: "contact",
        name: "Contact",
        component: () => import("@/views/about/index")
      }
    ]
  },
  {
    path: "/researchResult", // 法律研究成果
    component: Layout,
    redirect: "/researchResult/types",
    children: [
      {
        path: "types",
        name: "Types",
        component: () => import("@/views/researchResult/types")
      },
      {
        path: "list",
        name: "List",
        component: () => import("@/views/researchResult/list")
      },
      {
        path: "details",
        name: "Details",
        component: () => import("@/views/researchResult/details")
      }
    ]
  },
  {
    path: "/lawServiceProduct", // 法律服务产品
    component: Layout,
    redirect: "/lawServiceProduct/list",
    children: [
      {
        path: "list",
        name: "List",
        component: () => import("@/views/serviceProduct/list")
      },
      {
        path: "details",
        name: "Details",
        component: () => import("@/views/serviceProduct/details")
      }
    ]
  },
  {
    path: "/think",
    component: Layout,
    children: [
      {
        path: "",
        name: "think",
        component: () => import("@/views/think/index")
      }
    ]
  },
  {
    path: "/information",
    redirect: "/information/policy",
    component: Layout,
    children: [
      {
        path: "",
        name: "information",
        redirect: "/information/policy",
        component: InfoLayout,
        children: [
          {
            path: "policy",
            name: "政策文件",
            component: () => import("@/views/information/policy/index")
          },
          {
            path: "train",
            name: "Train",
            component: () => import("@/views/information/train/list/index")
          },

          {
            path: "notice",
            name: "Notice",
            component: () => import("@/views/information/notice/list/index")
          },
          {
            path: "news",
            name: "新闻资讯",
            component: () => import("@/views/information/news")
          },
          {
            path: "trends",
            name: "服务动态",
            component: () => import("@/views/information/trends")
          }
        ]
      },
      {
        path: "dynamic",
        name: "Dynamic",
        component: () => import("@/views/information/dynamic/index")
      },
      {
        path: "trends/detail",
        name: "服务动态详情",
        component: () => import("@/views/information/trends/detail")
      },
      {
        path: "trainDetail",
        name: "TrainDetail",
        component: () => import("@/views/information/train/detail/index")
      },
      {
        path: "noticeDetail",
        name: "NoticeDetail",
        component: () => import("@/views/information/notice/detail/index")
      }
    ]
  },
  {
    path: "/communication",
    component: Layout,
    redirect: "/communication/policy",
    children: [
      // {
      //   path: "",
      //   name: "communication",
      //   component: () => import("@/views/communication/index"),
      //   meta: {
      //     // keepAlive: true
      //   }
      // },
      // 新增这个/a 路由是为了解决面包屑导航问题，因为所有tab分页和交流合作区是一个路由
      // {
      //   path: "a",
      //   component: () => import("@/views/communication/index"),
      // },
      {
        path: "policy",
        name: "Policy",
        component: () => import("@/views/communication/index")
      },
      {
        path: "policy/detail",
        name: "PolicyDetail",
        component: () => import("@/views/communication/news/policyDetail")
      },
      {
        path: "news",
        name: "News",
        component: () => import("@/views/communication/index")
      },
      {
        path: "news/detail",
        name: "NewsDetail",
        component: () => import("@/views/communication/news/newsDetail")
      },
      {
        path: "case",
        name: "Case",
        component: () => import("@/views/communication/index")
      },
      {
        path: "case/detail",
        name: "CaseDetail",
        component: () => import("@/views/communication/case/detail")
      },
      {
        path: "article",
        name: "Article",
        component: () => import("@/views/communication/index")
      },
      {
        path: "article/detail",
        name: "ArticleDetail",
        component: () => import("@/views/communication/article/detail")
      },
      {
        path: "notice",
        name: "Notice",
        component: () => import("@/views/communication/index")
      },
      {
        path: "notice/detail",
        name: "Notice/Detail",
        component: () => import("@/views/communication/notice/noticeDetail")
      },
      {
        path: "activity",
        name: "Activity",
        component: () => import("@/views/communication/index")
      },
      {
        path: "activity/detail",
        name: "ActivityDetail",
        component: () => import("@/views/communication/notice/activityDetail")
      },
      {
        path: "ask",
        name: "Ask",
        component: () => import("@/views/communication/index")
      },
      {
        path: "ask/detail",
        name: "AskDetail",
        component: () => import("@/views/communication/ask/detail")
      },
      {
        path: "experts",
        name: "Experts",
        component: () => import("@/views/communication/index")
      },
      {
        path: "experts/detail",
        name: "ExpertsDetail",
        component: () => import("@/views/communication/experts/detail")
      },
      {
        path: "lawyer",
        name: "Lawyer",
        component: () => import("@/views/communication/index")
      },
      {
        path: "lawyer/detail",
        name: "LawyerDetail",
        component: () => import("@/views/communication/lawyer/detail")
      },
      {
        path: "lawyer/consult",
        name: "LawyerConsult",
        component: () => import("@/views/communication/lawyer/consult")
      },
      // 上合论坛详情
      {
        path: "forum",
        name: "Forum",
        component: () => import("@/views/communication/index")
      },
      {
        path: "forum/detail",
        name: "ForumDetail",
        component: () => import("@/views/communication/forum/Detail")
      },

    ]
  },
  {
    path: "/usercenter",
    redirect: "/usercenter/profile",
    component: Layout,
    children: [
      {
        path: "",
        name: "usercenter",
        redirect: "/usercenter/profile",
        component: UserLayout,
        children: [
          {
            path: "message",
            name: "Message",
            component: () => import("@/views/userCenter/message")
          },
          {
            path: "profile",
            name: "我的信息",
            component: () => import("@/views/userCenter/profile/index")
          },
          {
            path: "organization",
            name: "机构信息",
            component: () => import("@/views/userCenter/organization/index")
          },
          {
            path: "safety",
            name: "帐号安全",
            component: () => import("@/views/userCenter/safety/index")
          },
          {
            path: "system",
            name: "System",
            component: () => import("@/views/userCenter/system")
          },
          {
            path: "myBBS",
            name: "MyBBS",
            component: () => import("@/views/userCenter/myBBS")
          },
          {
            path: "download",
            name: "MyBBS",
            component: () => import("@/views/userCenter/download")
          }
        ]
      }
    ]
  }
];

if (process.env.NODE_ENV !== "production") {
  routes.push({
    path: "/demo",
    component: Layout,
    children: [
      {
        path: "",
        name: "demo",
        component: () => import("@/views/demo/index")
      }
    ]
  });
}

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
