import API from '@/api'
import { log } from '@/utils/helper'
import {
  getToken,
  setToken,
  removeToken,
  getUserInfo,
  setUserInfo,
  removeUserInfo
} from '@/utils/auth'
// import { resolve } from 'core-js/fn/promise'

const state = {
  token: getToken(),
  userInfo: getUserInfo(),
  orgIntro: {}
}

const getters = {
  token(state) {
    return state.token
  },
  userInfo(state) {
    return state.userInfo
  },
  orgIntro(state) {
    return state.orgIntro
  }
}

const actions = {
  loginOut: ({ commit }, data) => {
    return API.account.loginOut(data)
      .then(res => {
        if (res.code === '000000') {
          commit('resetUserInfo')
        }
        return res
      })
  },
  LoginIn: ({ commit, dispatch }, loginData = {
    data: {
      // phone: '13987654333', // 企业
      phone: '13540239926' // 机构
      // phone: '13987654222' // 律师
    },
    loginType: 'userLoginByPhone',
  }) => {
    return API.account[loginData.loginType](loginData.data)
      .then((res) => {
        if (res.accessToken) {
          commit('userLoginByPhone', res)
          setToken(res.accessToken)
          setUserInfo(res)
          localStorage.setItem('userInfo', JSON.stringify(res))
          // if (['LAW_OFFICE', 'TRANSLATE', 'OTHER'].includes(res.userType)) {
          //   setTimeout(() => dispatch('getOrganizationDetail'), 1000)
          // }
        }
        return res
      })
      .catch(() => {
        log('Interface exception API.account.LoginIn()')
      })
  },
  getOrgIntro: ({ commit, state }) => {
    // if(state.orgIntro)
    return API.account.getOrgIntro().then(res => {
      if (res) {
        commit('setOrgIntro', res)
        return true
      }
      return false
    })
  },
  updateAvatar: ({ commit }, data) => {
    return API.account.updateAvatar(data)
      .then(res => {
        if (res) {
          commit('updateAvatar', data)
        }
      })
  },
  updateAccount: ({ commit, state }, data) => {
    return API.account.update(data, state.userInfo.userType)
      .then(res => {
        if (res) {
          commit('updateAccount', data)
          return true
        }
        return false
      })
  },
  changeUserInfo: ({ commit }, data) => {
    commit('updateAccount', data)
  }
}

const mutations = {
  userLoginByPhone: (state, data) => {
    state.userInfo = data || {}
  },
  // remove token
  resetUserInfo(state, data) {
    return new Promise((resolve) => {
      removeToken()
      removeUserInfo()
      state.token = ''
      state.userInfo = {}
      resolve()
    })
  },

  updateAvatar: (state, data) => {
    // 更新内存用户数据。
    state.userInfo.picture = data.picture
    // 更新缓存用户数据。
    setUserInfo(state.userInfo)
  },
  updateAccount: (state, data) => {
    // 更新内存用户数据。
    state.userInfo = { ...state.userInfo, ...data }
    // 更新缓存用户数据。
    setUserInfo(state.userInfo)
  },
  setOrgIntro: (state, data) => {
    // debugger
    state.orgIntro = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
