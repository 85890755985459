/*  API
 *
 *  @init
 *
 */
import { autoExport } from '@/utils/helper'

const context = require.context('@/api', true, /\w*\.js$/)

const API = autoExport(context, 'api')

export default API
