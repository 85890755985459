/**
 * 公共模块API模块
 */

import request from '@/utils/request'

export default {
  getLawRequirements: (params) => {
    return request.get('/api/frLegalPlatform/web/lawRequirements/page',
      {
        params: params
      }
    )
  },
  getLegalResearchResult: (params) => {
    return request.get('/api/frLegalPlatform/web/legalResearchResult/list', { params })
  },
  getLegalServiceProduct: (params) => {
    return request.get('/api/frLegalPlatform/web/legalServiceProduct/page', { params })
  },
  getLawService: (params) => {
    return request.get('/api/frLegalPlatform/web/lawService/page',
      {
        params: params
      }
    )
  },
  getLawOffices: () => {
    return request.get('/api/frLegalPlatform/common/lawOfficeList')
  },
  getDictionaryList: (params) => {
    const arr = [ 'LAW_SERVICE', 'LAW_REQUIREMENTS', 'JUDICIAL_CASE', 'LAW_REGULATIONS' ]
    let path = '/api/frLegalPlatform/common/dictionary/list'
    if (arr.includes(params.type)) {
      path = '/api/frLegalPlatform/common/dictionary/country/list'
    }
    return request.get(path,
      {
        params: params
      }
    )
  },
  getAreaList: (params = {}) => {
    const arr = [ 'LAW_SERVICE', 'LAW_REQUIREMENTS', 'JUDICIAL_CASE', 'LAW_REGULATIONS' ]
    let path = '/api/frLegalPlatform/common/area/list'
    if (arr.includes(params.selectType)) {
      path = '/api/frLegalPlatform/common/area/province/list'
    }
    return request.get(path, {
      params
    })
  },
  getAreaTree: (params = {}) => {
    return request.get('/api/frLegalPlatform/common/area/tree/list', { params })
  },
  getAffairsTreeList: (params = {}) => {
    return request.get('/api/frLegalPlatform/common/area/foreign/affairs/tree/list', { params })
  },
  getTranslateKeyword: (url) => {
    return request.get(url,
    )
  },
  // 获取星象token
  getXinXToken: (params) => {
    return request.get('/api/frLegalPlatform/common/xx/token', { params })
  },

  getReport: () => {
    return request.get('/api/frLegalPlatform/web/companyReportRecord/latest')
  },

  setReportStatus: (id) => {
    return request.post(`/api/frLegalPlatform/web/companyReportRecord/down/status/${id}`)
  }
}
