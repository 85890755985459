/**
 * 交流合作专区 涉外律师 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/foreign/affairs/lawyer/page`, { params: data })
  },
  getDetail: (id, type) => {
    return request.get(`/api/frLegalPlatform/web/foreign/affairs/lawyer/${id}?type=${type}`)
  },
  consult: (id, data) => {
    return request.post(`/api/frLegalPlatform/web/foreign/affairs/lawyer/contact/${id}`, data)
  },
  consultDetail: (id, data) => {
    return request.get(`/api/frLegalPlatform/web/foreign/affairs/lawyer/contact/${id}`, { params: data })
  }
}
