<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import Footer from '@/components/common/Footer'
// import Header from '@/components/common/Header'
import { Notification } from 'element-ui'
import { delCookie } from './utils/cookie'
import { downloadFile, dealJump, haveReportDown } from './utils/reportDown'
import API from '@/api'
import { isLogin } from "@/utils/jurisdiction"

export default {
  name: 'APP',
  components: {
    Notification
    // Footer,
    // Header,
  },
  data() {
    return {
      timeoutId: null,
      oldReportId: null
    }
  },
  created() {
    delCookie('googtrans')
    if (isLogin()) {
      this.getReportResult()
    }
  },
  methods: {
    showNotification(data) {
      const _this = this
      _this.$notify({
        customClass: 'reportNotify',
        message: <div>{data.name}报告已提交请点击此处<a onclick={() => {
          downloadFile(data, (data) => {
            haveReportDown(data.id, this.getReportResult)
          })
        }}>下载企业报告。</a><div>企业报告记录请到<a onclick={() => { this.oldReportId = null; dealJump('/usercenter/download') }}>个人中心-我的下载</a>查看。</div></div>,
        duration: 0,
        onClick: () => {
          this.oldReportId = null
          const storyTimeoutId = sessionStorage.getItem('timeoutId')
          storyTimeoutId && clearTimeout(storyTimeoutId)
          sessionStorage.setItem('timeoutId', '')
        }
      })
    },
    getReportResult() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setInterval(() => {
        API.common.getReport().then(res => {
          if (res && res?.url) {
            // clearTimeout(this.timeoutId)
            // sessionStorage.setItem('timeoutId', '')
            if (res?.id !== this.oldReportId) {
              this.oldReportId = res?.id
              this.showNotification(res)
            }
          }
        })
      }, 5000)
      sessionStorage.setItem('timeoutId', this.timeoutId)
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.reportNotify{
  top: 80px !important;
}
</style>
