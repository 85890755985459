import router from './router'
// import store from './store'
// import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from cookie
// import { queryToString } from '@/utils/helper'

// const whiteList = ['/service/lawyer', '/service/arbitration', '/service/mediate', '/service/notarization', '/service/appraisal', '/service/legalAid'] // no redirect whitelist
const loginRequiredList = ['/service/demand/details', '/service/desk/details', '/release', '/legal/details', '/legal/apply', '/business/release', '/content', '/usercenter'] // no redirect whitelist
// const isWhiteList = function(path) {
//   // let result = false
//   // for (let index = 0; index < whiteList.length; index++) {
//   //   const element = whiteList[index]
//   //   if (path.startsWith(element)) {
//   //     result = true
//   //     break
//   //   }
//   // }
//   // return result
//   return whiteList.indexOf(path) !== -1
// }
const isLoginRequiredList = function(path) {
  let result = false
  for (let index = 0; index < loginRequiredList.length; index++) {
    const element = loginRequiredList[index]
    if (path.includes(element)) {
      result = true
      break
    }
  }
  return result
}

const loginPath = '/account/login'

router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === loginPath) {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
      next()
      // const hasGetUserInfo = store.getters.userInfo.accessToken
      // if (hasGetUserInfo) {
      //   next()
      // } else {
      //   try {
      //     // get user info
      //     await store.dispatch('account/userLoginByPhone')

      //     next()
      //   } catch (error) {
      //     // remove token and go to login page to re-login
      //     await store.dispatch('account/resetToken')
      //     Message.error(error || 'Has Error')
      //     next(`/login?redirect=${to.path}`)
      //   }
      // }
    }
  } else {
    /* has no token*/
    // try {
    //   // get user info
    //   await store.dispatch('LoginIn')
    //   next()
    // } catch (error) {
    //   // remove token and go to login page to re-login
    //   await store.dispatch('resetUserInfo')
    //   Message.error(error || 'Has Error')
    //   next(`/account/login?redirect=${to.path}`)
    // }
    // next()
    // if (isWhiteList(to.path)) {
    //   // in the free login whitelist, go directly
    //   next()
    // } else {
    //   // other pages that do not have permission to access are redirected to the login page.
    //   next(`/login?redirect=${to.path}`)
    // }
    if (isLoginRequiredList(to.path)) {
      // in the free login whitelist, go directly
      // next(`${loginPath}?redirect=${to.path}&${queryToString(to.query)}`)
      next(`${loginPath}`)
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next()
    }
  }
})
