/**
 * 公共模块API模块
 */

import request from '@/utils/request'
import { pickBy } from 'lodash'

const timeout = 15 * 1000
export default {
  getLawRegulationsList: (data = {}) => {
    const newData = pickBy(data, item => item)
    return request.post('/api/frLegalPlatform/web/lawRegulations/page', newData)
  },
  // 获取案例列表
  getJudicialCaseList: (data = {}) => {
    const newData = pickBy(data, item => item)
    return request.post('/api/frLegalPlatform/web/judicialCase/page', newData, { timeout })
  },
  getLawRegulationsDetails: (params) => {
    return request.get(`/api/frLegalPlatform/web/lawRegulations/${params.id}`, { params })
  },
  // 获取案例详情
  getJudicialCaseDetails: (params) => {
    return request.get(`/api/frLegalPlatform/web/judicialCase/${params.id}`, { params, timeout })
  },
  add: (data) => {
    return request.post('/api/frLegalPlatform/web/lawSearchApply/add', data)
  },
  getJudicialCaseSelect: (params) => {
    return request.get(`/api/frLegalPlatform/web/judicialCase/select`, { params: params })
  },
  getAnalysisData: (params) => {
    return request.post(`/api/frLegalPlatform/web/judicialCase/case/analysis`, null, {
      params,
    })
  },
}
