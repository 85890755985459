import axios from 'axios'
// import qs from 'qs'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import i18n from '@/i18n'
import router from '@/router'
import { log } from '@/utils/helper'
import { dealReportModalClose } from './reportDown'
// import { pickBy } from 'lodash'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 10000,
  // transformRequest: [function(data) {
  //   // `transformRequest` 允许在向服务器发送前，修改请求数据
  //   // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
  //   const newData = pickBy(data, item => item)
  //   console.log('newData', newData)
  //   return JSON.stringify(newData)
  // }],
})

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    let locale = i18n.locale
    // 判断国外
    const abroad = router.app._route.query.abroad
    if (abroad === 'abroad') locale = 'en'
    config.headers[ 'Accept-Language' ] = locale
    if (token) {
      config.headers[ 'accessToken' ] = token
    }
    return config
  },
  error => {
    // do something with request error
    log('service.interceptors.request.use', error) // for debug
    return Promise.reject(error)
  }
)

const showMessage = function (message = 'Error', type = 'error') {
  Message({
    message: message,
    type: type,
    duration: 5 * 1000,
    showClose: true,
  })
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async (response) => {
    // console.log('response', response);
    const res = response.data
    // return res
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === '792101') {
      showMessage(res.message)
      // showMessage(res.message + '重新登录中')
      try {
        await store.commit('resetUserInfo')
        dealReportModalClose()
        window.location = '/account/login'
      } catch (error) {
        return Promise.reject(new Error(res.message || 'Error'))
      }
      // try {
      //   // get user info
      //
      // } catch (error) {
      //   // remove token and go to login page to re-login
      //   Message.error(error || 'Has Error')
      // }
      // return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code === '794110') {
      showMessage(res.message)
      router.go(-1)
    } else if (response.status !== 200) {
      showMessage(res.message)
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res.data ? res.data : res
    }
    // else if (res.code !== '000000') {
    //   showMessage(res.message)
    //   return Promise.reject(new Error(res.message || 'Error'))
    // }
  },
  error => {
    log('service.interceptors.response.use', error) // for debug
    showMessage(error.message)
    return Promise.reject(error)
  }
)

export default service
