<template>
  <div class="nav-list">
    <div
      v-for="(item, index) in navList"
      :key="index"
      :class="`nav-item ${activeClass(item.path)}`"
      @click="goto(item.path)"
    >
      {{ $t(item.name) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    navList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {}
  },
  methods: {
    goto(path) {
      this.$router.push({ path: path })
    },
    activeClass(path) {
      return this.$route.path === path ? 'active' : ''
    },
  },
}
</script>

<style scoped lang="less">
.nav-list {
  padding-left: 30px;
  .nav-item {
    padding: 20px 0 20px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);

    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;

    cursor: pointer;
  }
  .nav-item.active {
    color: rgba(0, 164, 255, 1);
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 25px;

      width: 2px;
      height: 14px;
      background: rgba(0, 164, 255, 1);
    }
  }
}
</style>
