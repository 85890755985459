import API from '@/api'
import TreeList from '@/utils/tree'
import { log } from '@/utils/helper'

const state = {
  test: '321312mapGetters',
  lawRequirementsObj: {},
  lawServiceObj: {},
  dictionaryList: {
    content: []
  },
  serviceAreaList: {
    content: []
  },
  provinceList: [],
  countryList: [],
  areaTree: new TreeList([]),
  areaMap: {},
  dictionaries: {},
  lawOffices: [],
  unreadMessage: {},
  affairsTreeList: [],
}

const getters = {
  test(state) {
    return state.test
  },
  lawRequirementsObj(state) {
    return state.lawRequirementsObj
  },
  lawServiceObj(state) {
    return state.lawServiceObj
  },
  dictionaryList(state) {
    return state.dictionaryList
  },
  serviceAreaList(state) {
    return state.serviceAreaList
  },
  provinceList(state) {
    return state.provinceList
  },
  countryList(state) {
    return state.countryList
  },
  areaTree(state) {
    return state.areaTree
  },
  areaMap(state) {
    return state.areaMap
  },
  dictionaries(state) {
    return state.dictionaries
  },
  lawOffices(state) {
    return state.lawOffices
  },
  unreadMessage(state) {
    return state.unreadMessage
  },
  affairsTreeList(state) {
    return state.affairsTreeList
  }
}

const actions = {
  getLawRequirements: ({ commit }, data) => {
    return API.common.getLawRequirements(data)
      .then((res) => {
        commit('getLawRequirements', res)
      }).catch(() => {
        log('Interface exception API.common.getLawRequirements()')
      })
  },
  getLawService: ({ commit }, data) => {
    return API.common.getLawService(data)
      .then((res) => {
        commit('getLawService', res)
      }).catch(() => {
        log('Interface exception API.common.getLawService()')
      })
  },
  getDictionaryList: ({ commit }, data) => {
    return API.common.getDictionaryList(data)
      .then((res) => {
        commit('getDictionaryList', res)
      }).catch(() => {
        log('Interface exception API.common.getDictionaryList()')
      })
  },
  getServiceAreaList: ({ commit }, data) => {
    return API.common.getDictionaryList(data)
    .then((res) => {
      commit('getServiceAreaList', res)
    }).catch(() => {
      log('Interface exception API.common.getDictionaryList()')
    })
  },
  getProvinceList: ({ commit }, data) => {
    return API.common.getAreaList(data)
      .then((res) => {
        commit('getProvinceList', res)
      }).catch(() => {
        log('Interface exception API.common.getProvinceList()')
      })
  },
  getCountryList: ({ commit }, data) => {
    return API.common.getAreaList(data)
      .then((res) => {
        commit('getCountryList', res)
      }).catch(() => {
        log('Interface exception API.common.getCountryList()')
      })
  },
  getAreaTree: ({ commit }, data) => {
    return API.common.getAreaTree(data)
      .then(res => {
        commit('getAreaTree', res)
      }).catch(() => {
        log('Interface exception API.common.getAreaTree()')
      })
  },
  getAffairsTreeList: ({ commit }, data) => {
    return API.common.getAffairsTreeList(data)
      .then(res => {
        commit('getAffairsTreeList', res)
      }).catch(() => {
        log('Interface exception API.common.getAffairsTreeList()')
      })
  },
  getDictionary: ({ commit, state }, data) => {
    if (state.dictionaries[data.type]) return
    return API.common.getDictionaryList(data)
      .then((res) => {
        commit('getDictionary', { res, params: data })
      }).catch(() => {
        log('Interface exception API.common.getDictionaryList()')
      })
  },
  getLawOffices: ({ commit }, data) => {
    if (state.lawOffices.length) return
    return API.common.getLawOffices(data)
      .then((res) => {
        commit('getLawOffices', res)
      }).catch(() => {
        log('Interface exception API.common.getLawOffices()')
      })
  },
  getUnread: ({ commit }, data) => {
    return API.userCenter.getUnread()
      .then((res) => {
        commit('getUnread', res)
      }).catch(() => {
        log('Interface exception API.common.getUnread()')
      })
  }
}

const mutations = {
  getLawRequirements: (state, data) => {
    state.lawRequirementsObj = data || {}
  },
  getLawService: (state, data) => {
    state.lawServiceObj = data || {}
  },
  getDictionaryList: (state, data) => {
    state.dictionaryList = data || {}
  },
  getServiceAreaList: (state, data) => {
    state.serviceAreaList = data || {}
  },
  getProvinceList: (state, data) => {
    state.provinceList = data.content || []
  },
  getCountryList: (state, data) => {
    state.countryList = data.content || []
  },
  getAreaTree: (state, data) => {
    state.areaTree = new TreeList(data.content) || new TreeList([])
  },
  getAffairsTreeList: (state, data) => {
    state.affairsTreeList = data.content || []
  },
  getDictionary: (state, { res, params }) => {
    if (res && res.content) {
      const map = res.content.reduce((r, i) => {
        r[i.id] = i.name
        return r
      }, {})

      state.dictionaries = {
        ...state.dictionaries,
        [params.type]: { map, list: res.content }
      }
    }
  },
  getLawOffices: (state, data) => {
    state.lawOffices = data.content || []
  },
  getUnread: (state, data) => {
    if (data) {
      state.unreadMessage = { ...data }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
