/**
 * 机构管理
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/organizationInfo/organizationMembers`, { params: data })
  },
  add(data) {
    return request.post('/api/frLegalPlatform/web/organizationInfo/members/add', data)
  },
  put(id, data) {
    return request.put(`/api/frLegalPlatform/web/organizationInfo/members/${id}`, data)
  },
  delete(ids) {
    return request.delete(`/api/frLegalPlatform/web/organizationInfo/members`, { data: ids })
  }
}
