/**
 * 交流合作专区 百问百答 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/ask/answer/page`, { params: data })
  },
  getDetail: (id, data) => {
    return request.get(`/api/frLegalPlatform/web/ask/answer/${id}`, { params: data })
  }
}
