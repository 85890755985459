/**
 * 资讯 政策 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/dynamicInformation/policy/page`, { params: data })
  },
}
