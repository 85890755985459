<template>
  <div :style="{background: '#f5f6f7', ...bodyStyle}">
    <div class="bilayout-content-box">
      <div v-if="navList" class="breadcrumb">
        <Breadcrumb :data="navList" />
      </div>
      <div class="bilayout-content">
        <div class="left">
          <slot name="left"></slot>
        </div>
        <div class="right">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

import './index.less'
export default {
  name: 'BiLayoutBox',
  components: {
    Breadcrumb,
  },
  props: {
    navList: Array,
    bodyStyle: {
      type: Object,
      defalut: () => ({})
    },
  },
  mounted() {},
  methods: {},
}
</script>
