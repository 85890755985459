
const urgentStatus = function(val) {
  const obj = {
    'EMERGENCY': '紧急',
    'NORMAL': '正常'
  }
  if (obj[val]) {
    val = obj[val]
  }
  return val
}
export default urgentStatus
