import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { Base64 } from "js-base64"

export const base64Password = (password) => {
  return Base64.encode('fzg:' + password)
}
export const validateMobilePhone = (rule, value, callback) => {
  if (value && !isMobilePhone(value, 'zh-CN')) {
    callback(new Error('请输入正确手机号'))
  }
  callback()
}
export const log = console.log.bind(console)

export const queryToString = (query = {}) => {
  const result = []
  const arr = Object.keys(query)
  for (let index = 0; index < arr.length; index++) {
    const key = arr[index]
    const val = query[key]
    if (key !== 'redirect') {
      result.push(`${key}=${val}`)
    }
  }
  return result.join('&')
}

const getModuleName = (str) => {
  return str.replace(/\/(\w)/g, ($0, $1) => $1)
    .replace(/-(\w)/g, ($0, $1) => $1.toUpperCase())
    .replace(/\.js/g, '')
    .replace(/\./, '')
}

export const autoExport = (context, defaultName = 'app', ignoreFile = 'index') => {
  const result = {}
  context.keys().map(key => {
    const moduleName = getModuleName(key) || defaultName
    if (moduleName !== ignoreFile) {
      result[moduleName] = context(key).default || context(key)
    }
  })
  return result
}

export const translateObj = {
  appid: '20210818000920260',
  secretKey: 'h3spTfp5WMbOPoK6Z7ZB',
  salt: '1541254587',
}
