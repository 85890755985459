import i18n from "@/i18n"

const path2name = {
  '/': 'f43ad59', // '首页'
  '/content': 'f448c9a', // '内容管理',
  '/content/request/manage': '0956d5f', // '需求管理'
  '/content/request/cooperation': '7e467c2', // '需求合作'
  '/content/request/cooperation_detail': '2fafe31', // 合作详情,
  '/content/request/detail': '76b00ba', // '需求详情'
  '/content/request/release': '7e0fbbd', // '发布需求'
  '/content/service/manage': '0b2e1a0', // '服务管理'
  '/content/service/detail': '22404a4', // '服务详情'
  '/content/service/cooperation': 'd8a7e67', // '服务合作'
  '/content/service/cooperationDetail': '2fafe31',
  '/content/lookup': '83b4d31', // 查询管理,
  '/content/consult': 'c0576d8', // '咨询管理'
  '/content/lookup/detail': '9360f27',
  '/content/lookup/form': '5dc734e', // '申请查询',
  '/content/consult/detail': '839c66b', // '咨询详情'
}

const path2paths = {
  '/content/request/manage': ['/', '/content', '/content/request/manage'],
  '/content/request/detail': ['/', '/content', '/content/request/manage', '/content/request/detail'],
  '/content/request/release': ['/', '/content', '/content/request/manage', '/content/request/release'],
  '/content/request/cooperation': ['/', '/content', '/content/request/cooperation'],
  '/content/request/cooperation_detail': ['/', '/content', '/content/request/cooperation', '/content/request/cooperation_detail'],
  '/content/service/detail': ['/', '/content', '/content/service/manage', '/content/service/detail'],

  '/content/lookup': ['/', '/content', '/content/lookup'],
  '/content/lookup/detail': ['/', '/content', '/content/lookup', '/content/lookup/detail'],
  '/content/lookup/form': ['/', '/content', '/content/lookup', '/content/lookup/form'],
  '/content/consult': ['/', '/content', '/content/consult'],
  '/content/consult/detail': ['/', '/content', '/content/consult', '/content/consult/detail'],

  '/content/service/manage': ['/', '/content', '/content/service/manage'],
  '/content/service/cooperation': ['/', '/content', '/content/service/cooperation'],
  '/content/service/cooperationDetail': ['/', '/content', '/content/service/cooperation', '/content/service/cooperationDetail'],
}

export function breadcrumbList(path) {
  let key
  for (const k of Object.keys(path2paths)) {
    if (k.indexOf(path) > -1) {
      key = k
      break
    }
  }
  if (key) {
    return path2paths[key].map((item) => {
      console.log(i18n.t(path2name[item]))
      return { name: i18n.t(path2name[item]), path: item }
    })
  }
  return []
}
