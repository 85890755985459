
import request from '@/utils/request';

export default {
  getLegalServiceProductPage: (params) => {
    return request.get(`/api/frLegalPlatform/web/legalServiceProduct/page`, { params });
  },
  getLegalServiceProductDetails: (id) => {
    return request.get(`/api/frLegalPlatform/web/legalServiceProduct/${id}`);
  },
  getLegalServiceProductTeams: (legalServiceProductId, params) => {
    return request.get(`/api/frLegalPlatform/web/legalServiceProduct/team/page/${legalServiceProductId}`, { params });
  },
  postLegalServiceProduct: (id) => {
    return request.post(`/api/frLegalPlatform/web/legalServiceProduct/${id}`);
  },
};
