/**
 * 上合论坛－论坛详情
 */

import request from '@/utils/request'

export default {
  getDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/bbs/${id}`)
  },
  getCommentList: (bbsId) => {
    return request.get(`/api/frLegalPlatform/web/bbs/comments/list/${bbsId}`)
  },
  addComment: (bbsId, params) => {
    return request.post(`/api/frLegalPlatform/web/bbs/comments/${bbsId}`, params)
  }
}
