/**
 * 交流合作专区 通知公告 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/notice/page`, { params: data })
  },
  getDetail: (id, data) => {
    return request.get(`/api/frLegalPlatform/web/notice/${id}`, { params: data })
  }
}
