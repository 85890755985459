import API from '@/api'
import { log } from '@/utils/helper'

const state = {
}

const getters = {

}

const actions = {
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
