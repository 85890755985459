import API from '@/api'
import { log } from '@/utils/helper'

const state = {
  statistics: {},
  detailsData: {},
  deskDetailsData: {
    cases: []
  },
  businessTypeList: [],
  organizationList: {
    content: []
  },
  organizationDetailsData: {},
  organizationPersonsDetailsData: {},
}

const getters = {
  statistics(state) {
    return state.statistics
  },
  detailsData(state) {
    return state.detailsData
  },
  deskDetailsData(state) {
    return state.deskDetailsData
  },
  organizationList(state) {
    return state.organizationList
  },
  organizationDetailsData(state) {
    return state.organizationDetailsData
  },
  organizationPersonsDetailsData(state) {
    return state.organizationPersonsDetailsData
  },
}

const actions = {
  getStatistics: ({ commit }, data) => {
    return API.service.getStatistics(data)
      .then((res) => {
        commit('getStatistics', res)
      }).catch(() => {
        log('Interface exception API.serice.getStatistics()')
      })
  },
  getLawRequirementsDetails: ({ commit }, data) => {
    return API.service.getLawRequirementsDetails(data)
      .then((res) => {
        commit('getLawRequirementsDetails', res)
      }).catch(() => {
        log('Interface exception API.serice.getLawRequirementsDetails()')
      })
  },
  getLawServiceDetails: ({ commit }, data) => {
    return API.service.getLawServiceDetails(data)
      .then((res) => {
        commit('getLawServiceDetails', res)
      }).catch(() => {
        log('Interface exception API.serice.getLawServiceDetails()')
      })
  },
  getOrganizationList: ({ commit }, data) => {
    return API.service.getOrganizationList(data)
      .then((res) => {
        commit('getOrganizationList', res)
      }).catch(() => {
        log('Interface exception API.serice.getOrganizationList()')
      })
  },
  getLawyerList: ({ commit }, data) => {
    return API.service.getLawyerList(data)
      .then((res) => {
        commit('getOrganizationList', res)
      }).catch(() => {
        log('Interface exception API.serice.getLawyerList()')
      })
  },
  getOrganizationDetails: ({ commit }, data) => {
    return API.service.getOrganizationDetails(data)
      .then((res) => {
        commit('getOrganizationDetails', res)
      }).catch(() => {
        log('Interface exception API.serice.getOrganizationDetails()')
      })
  },
  getOrganizationPersonsDetails: ({ commit }, data) => {
    return API.service.getOrganizationPersonsDetails(data)
      .then((res) => {
        commit('getOrganizationPersonsDetails', res)
      }).catch(() => {
        log('Interface exception API.serice.getOrganizationPersonsDetails()')
      })
  },
}

const mutations = {
  getStatistics: (state, data) => {
    state.statistics = data || {}
  },
  getLawRequirementsDetails: (state, data) => {
    state.detailsData = data || {}
  },
  getLawServiceDetails: (state, data) => {
    state.deskDetailsData = data || {}
  },
  getOrganizationList: (state, data) => {
    state.organizationList = data || {}
  },
  getOrganizationDetails: (state, data) => {
    state.organizationDetailsData = data || {}
  },
  getOrganizationPersonsDetails: (state, data) => {
    state.organizationPersonsDetailsData = data || {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
