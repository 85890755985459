/**
 * 公共模块API模块
 */
// import axios from 'axios'
import request from '@/utils/request'
// const transformRequest = function(data) {
//   let ret = ''
//   for (let it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   ret = ret.substring(0, ret.lastIndexOf('&'))
//   return ret
// }

export default {
  loginOut: (data) => {
    return request.post(`/api/frLegalPlatform/web/user/loginOut`, data)
  },
  userLoginByAccount: (data) => {
    return request.post(`/api/frLegalPlatform/web/user/account/login`, data)
  },
  userLoginByPhone: (data) => {
    return request.post(`/api/frLegalPlatform/web/user/phone/login`, data)
  },
  updateAvatar(data) {
    return request.put(`/api/frLegalPlatform/web/user/setPicture`, data)
  },
  // 获取手机验证码
  sendSms(data) {
    return request.post(`/api/frLegalPlatform/common/sms/sendSms`, data)
  },
  // // 获取获取邮件验证码
  // getEmailCode(data) {
  //   return request.post(`/api/frLegalPlatform/common/getEmailCode`, data)
  // },
  // 验证短信验证码
  cheekedSmsCode(data) {
    return request.post(`/api/frLegalPlatform/common/sms/cheekedSmsCode`, data)
  },
  // 获取验证码
  getVerify() {
    return request.get(`/api/frLegalPlatform/common/getVerify`)
  },
  // 验证图形验证码
  checkVerify(code) {
    return request.post(`/api/frLegalPlatform/common/checkVerify?verifyInput=${code}`)
  },
  // 获取邮箱验证码
  getEmailCode(data) {
    return request.post(`/api/frLegalPlatform/common/getEmailCode`, data)
  },
  // 验证邮箱验证码
  checkEmailCode(data) {
    return request.post(`/api/frLegalPlatform/common/checkEmailCode`, data)
  },
  // 找回密码-发送邮件
  retrievePassword(data) {
    return request.get(`/api/frLegalPlatform/web/user/email/retrievePassword`, { params: data })
  },
  // 找回密码
  resetPassword(data) {
    return request.put(`/api/frLegalPlatform/web/user/retrievePassword`, data)
  },
  // 个人用户注册
  userRegister(data) {
    return request.post(`/api/frLegalPlatform/web/user/register`, data)
  },
  // 企业注册
  enterpriseRegister(data) {
    const url = '/api/frLegalPlatform/web/user/enterprise/register'
    return request.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    // return axios({
    //   method: 'post',
    //   url: url,
    //   data,
    //   transformRequest: [transformRequest],
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // })
    // return request.post(`/api/frLegalPlatform/web/user/enterprise/register`, data)
  },
  // 律师注册
  lawyerRegister(data) {
    return request.post(`/api/frLegalPlatform/web/user/lawyer/register`, data)
  },
  // 机构注册
  organizationRegister(data) {
    return request.post(`/api/frLegalPlatform/web/user/organization/register`, data)
  },
  // 律所下拉列表
  getLawOfficeList() {
    return request.get(`/api/frLegalPlatform/common/lawOfficeList`)
  },
  // 用户中心 账户修改
  update(data, type) {
    if (type === 'PERSON') {
      return request.put(`/api/frLegalPlatform/web/user/setPicture`, data)
    } else if (type === 'LAWYER') {
      return request.put('/api/frLegalPlatform/web/lawyerInfo/update', data)
    } else if (type === 'ENTERPRISE') {
      return request.put('/api/frLegalPlatform/web/companyInfo/update', data)
    } else {
      return request.put('/api/frLegalPlatform/web/organizationInfo/update', data)
    }
  },
  getAccount(type) {
    if (type === 'LAWYER') {
      return request.get('/api/frLegalPlatform/web/lawyerInfo/detail')
    } else if (type === 'ENTERPRISE') {
      return request.get('/api/frLegalPlatform/web/companyInfo/detail')
    } else {
      return request.get('/api/frLegalPlatform/web/organizationInfo/detail')
    }
  },
  getOrgIntro() {
    return request.get('/api/frLegalPlatform/web/organizationInfo/introduction')
  },
  updateOrgIntro(data) {
    return request.put('/api/frLegalPlatform/web/organizationInfo/introduction', data)
  },
  changePhone(data) {
    return request.put('/api/frLegalPlatform/web/user/changePhone', data)
  },
  changeEmail(data) {
    return request.put('/api/frLegalPlatform/web/user/changeEmail', data)
  },
  changePasswd(data) {
    return request.put('/api/frLegalPlatform/web/user/setPassWord', data)
  }
}
