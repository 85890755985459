/**
 * 内容管理 需求管理 API
 */

import request from '@/utils/request'

export default {
  get: (data) => {
    return request.get(`/api/frLegalPlatform/web/lawRequirements/user/page`, { params: data })
  },
  delete: (id) => {
    return request.delete(`/api/frLegalPlatform/web/lawRequirements/${id}`)
  },
  getDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/lawRequirements/user/${id}`)
  },
  getServiceList: (data) => {
    return request.get(`/api/frLegalPlatform/web/lawRequirements/user/service/page`, { params: data })
  },
  deleteLawService: (id) => {
    return request.delete(`/api/frLegalPlatform/web/lawService/${id}`)
  },
  getCooperationDetail: (id) => {
    return request.get(`/api/frLegalPlatform/web/lawRequirements/user/service/${id}`)
  },
  setCommunicated: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/communicated/${id}`)
  },
  setCooperation: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/cooperation/${id}`)
  },
  setComplete: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/completed/${id}`)
  },
  cancelOrder: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/cancel/cooperation/${id}`)
  },
  republish: (id, data = {}) => {
    return request.post(`/api/frLegalPlatform/web/lawRequirements/republish/${id}`, data)
  },
  cancelApply: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/cancel/signUp/${id}`)
  },
  closeRequest: (id) => {
    return request.put(`/api/frLegalPlatform/web/lawRequirements/cancel/${id}`)
  }
}
