<template>
  <div class="header">
    <div class="container">
      <div class="logo"></div>
      <div class="navMenu">
        <div
          v-for="(path, index) of routerData"
          :key="index"
          class="uiText"
          :class="{ active: index === activeIndex }"
          @click="changeActiveIndex(path, index)"
        >
          <div v-if="!Array.isArray(path)">
            {{ handleContext(path, index) }}
          </div>
          <el-dropdown
            v-if="Array.isArray(path)"
            @command="(path) => changeActiveIndex(path, index)"
          >
            <span :class="{ active: index === activeIndex }">
              {{ handleContext(path, index, 0)
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(childrenPath, childrenIndex) of path"
                :command="path[childrenIndex + 1]"
              >
                {{ handleContext(path, index, childrenIndex + 1) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="lang">
        <div
          v-for="(item, index) of langData"
          :key="index"
          class="interval"
          :class="{ active: activeLang === item.lang }"
          @click="changeLang(item.lang, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <el-dropdown v-if="userInfo.userType" @command="handleCommand">
        <div class="userInfo">
          <img :src="userInfo.picture || head" alt="" />
          {{ userInfo.username || "Admin" }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/content/request/manage">
            {{ $t("f448c9a") }}
          </el-dropdown-item>
          <el-dropdown-item command="/usercenter/profile">
            {{ $t("e34051b") }}
          </el-dropdown-item>
          <el-dropdown-item command="logOut">
            {{ $t("b675167") }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else class="account">
        <div class="login" @click="goLogin">{{ $t("common.sign") }}</div>
        <div class="register" @click="goRegister">
          {{ $t("common.register") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { dealReportModalClose } from "../utils/reportDown"

export default {
  name: "Header",
  data() {
    return {
      activeIndex: "1",
      googtrans: "",
      activeLang: localStorage.getItem("lang") || "zh",
      langData: [
        {
          name: "中",
          lang: "zh",
        },
        {
          name: "EN",
          lang: "en",
        },
        {
          name: "ru",
          lang: "ru",
        },
      ],
      routerData: [
        "/home",
        "/service",
        "/legal",
        "/lawServiceProduct",
        "/researchResult",
        ["", "/communication/policy", "/business/consult"],
        // '/think',
        // '/information/policy',
        // "/business",
        // "/service/aiLegal",
        "/about",
      ],
      head: require("@/assets/common/head.png"),
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    $route(to, from) {
      this.handleActiveIndex()
    },
  },
  created() {
    this.handleActiveIndex()
  },
  methods: {
    a() {
      window.translator2.dispatchEvent.call(
        window.translator2,
        "clk_no_ap_site"
      )
    },
    ...mapActions(["loginOut"]),
    handleCommand(command) {
      if (command.includes("/")) {
        this.$router.push({
          path: command,
        })
      } else {
        this[command]()
      }
    },
    logOut() {
      this.loginOut().then((res) => {
        if (res.code === "000000") {
          dealReportModalClose()
          this.$router.push({
            path: "/home",
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    goTo(path) {
      // this.activeIndex = path;
      this.$router.push({ path })
      // this.$emit("select", path);
    },
    handleContext(path, index, childrenIndex) {
      const str = "common.pageList"
      if (Array.isArray(path)) {
        return this.$t(str)[index][childrenIndex]
      } else {
        return this.$t(str)[index]
      }
    },
    goLogin() {
      this.$router.push({
        path: "/account/login",
      })
    },
    goRegister() {
      this.$router.push({
        path: "/account/register",
      })
    },
    changeLang(lang, index) {
      this.activeLang = lang
      this.$i18n.locale = lang
      localStorage.setItem("lang", lang)
    },
    handleActiveIndex() {
      for (let index = 0; index < this.routerData.length; index++) {
        const element = this.routerData[index]
        if (!Array.isArray(element)) {
          if (this.$route.path.includes(element)) {
            this.activeIndex = index
            break
          } else {
            this.activeIndex = ""
          }
        } else {
          if (element.includes(this.$route.path)) {
            this.activeIndex = index
            break
          } else {
            this.activeIndex = ""
          }
        }
      }
    },
    changeActiveIndex(path, index) {
      if (!Array.isArray(path)) {
        this.activeIndex = index
        this.$router.push({
          path: path,
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
/*@import '~@/styles/common.less';*/

.header {
  width: 100%;
  background: #001529;
  color: #ffffff;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    width: @uni-width;
    margin: 0 auto;
    justify-content: space-between;
  }

  .logo {
    width: 28px;
    height: 28px;
    margin-right: 60px;
    background: url("~@/assets/common/logo.png") center no-repeat;
    background-size: 28px 28px;
  }

  .navMenu {
    display: flex;
    height: 64px;
    line-height: 64px;
    font-size: 14px;
    font-weight: 400;

    .uiText {
      box-sizing: border-box;
      display: block;
      margin-right: 20px;
      position: relative;
      word-break: break-all;
      max-width: 135px;
      padding: 0 10px;
      color: #ffffff;
      cursor: pointer;
      overflow: hidden;
      .el-dropdown {
        color: #ffffff;
      }
    }

    .active {
      color: @uni-text-color-select;
      font-weight: bold;

      &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        content: "";
        width: 100%;
        height: 3px;
        background: @uni-text-color-select;
        border-radius: 2px;
      }
    }
  }

  .lang {
    display: flex;
    margin: 0 70px 0 40px;
    font-size: 14px;
    font-family: Helvetica;
    color: rgba(255, 255, 255, 0.6);

    .interval {
      position: relative;
      margin-right: 25px;
      cursor: pointer;

      &::after {
        position: absolute;
        right: -12.5px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 6px;
        background: rgba(221, 221, 221, 0.6);
      }
    }

    .active {
      color: #fff;
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
    color: #ffffff;

    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border-radius: 50%;
      opacity: 0.8;
    }
  }

  .account {
    display: flex;
    align-items: center;
    cursor: pointer;

    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      padding: 10px 12px;
      background: @uni-bg-color-button;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
